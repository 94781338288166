import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { Dialog } from '../../models/dialog.model';

@Injectable({
  providedIn: 'root',
})
export class GeneralCoreService {
  private dialogSubject = new Subject<Dialog>();
  public dialog$: Observable<Dialog> = this.dialogSubject.asObservable();

  private navbarMenuSubject = new Subject<Dialog>();
  public navbarMenu$: Observable<Dialog> =
    this.navbarMenuSubject.asObservable();

  private buttomSheetMenuSubject = new Subject<boolean>();
  public buttomSheetMenu$: Observable<boolean> =
    this.buttomSheetMenuSubject.asObservable();

  private spinnerSubject = new Subject<boolean>();
  public spinner$: Observable<boolean> = this.spinnerSubject.asObservable();

  private estadoCoberturasSubject = new BehaviorSubject<boolean>(false);
  public estadoCoberturas$: Observable<boolean> =
    this.estadoCoberturasSubject.asObservable();

  constructor() {}

  openDialog = (data: any) => this.dialogSubject.next(data);
  openSpinner = (data) => this.spinnerSubject.next(data);
  changeEstadoCoberturas = (data) => this.estadoCoberturasSubject.next(data);
  openMenu = (data) => this.navbarMenuSubject.next(data);
  openButtomSheetMenu = (data) => this.buttomSheetMenuSubject.next(data);

  get estadoCoberturas() {
    return this.estadoCoberturasSubject.value;
  }
}
